<template>
    <section class="section-imagenes-component pt-2 pb-3">
        <div class="row mx-0">
            <cargando v-if="cargando" />
            <div class="text-center" style="width:35px;">
                <el-tooltip content="Inactivo" placement="bottom" effect="light">
                    <i v-if="!activeComponent" class="icon-eye-off f-18 text-general cr-pointer" />
                </el-tooltip>
            </div>
            <div class="col px-0">
                <div class="row mx-0 align-items-center text-gris2 f-500 mb-2">
                    Sección imagenes
                    <span class="ml-4 text-general f-20 f-600">
                        <el-tooltip content="Clicks de la sección" placement="bottom" effect="light">
                            <i class="icon-cursor" />
                        </el-tooltip>
                        {{ totalClicks }}
                    </span>
                    <button type="button" class="btn-action border mr-2 ml-auto">
                        <el-popover
                        ref="optionsPopover"
                        placement="bottom"
                        trigger="click"
                        width="200"
                        popper-class="p-0 br-none"
                        >
                            <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('editar')">
                                Editar
                            </div>
                            <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('ordenar')">
                                Ordenar sección
                            </div>
                            <div v-if="data.estado == 1" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('activar')">
                                Inactivar
                            </div>
                            <div v-if="data.estado == 0" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('inactivar')">
                                Activar
                            </div>
                            <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('eliminar')">
                                Eliminar
                            </div>
                            <i slot="reference" class="icon-dots-vertical f-20 text-general cr-pointer" />
                        </el-popover>
                    </button>
                </div>
                <template v-if="verData">
                    <template v-if="tipoSlider">
                        <!--
                        slide-multiple, boolean
                        Permite mostrar varios tipos de imagenes  en un solo panel de slide.

                        visible-slides, number
                        se le pasa la cantidad de imagenes que muestra por slide

                        --alto-slide, variable
                        se crea esta variable para poder tener control del alto cuando se es tipo slides en las imagenes, el alto
                        tiene que se el mismo de las imagenes para que no corte ninguna de estas.

                        touchable, boolean
                        se encarga de poder arrastrar las imagenes con el mouse, no tocar!

                        bulltes, boolean
                        muestra un acceso directo para las imagenes en general, no tocar!

                        arrow-outside, boolean
                        muestra las flechas dentro o fuera del componente, por el diseño estan dentro, no tocar!

                        <vueper-slides
                        ref="vueperslides1"
                        :bullets="false"
                        :visible-slides="3"
                        :slide-multiple="true"
                        class="no-shadow h-vueper-component"
                        :arrows-outside="false"
                        :touchable="false"
                        :style="`--alto-slide:${altoMaximo}px`"
                        >
                            <template #arrow-left>
                                <div class="d-middle-center bg-white rounded-circle shadow" style="width:32px;height:32px;">
                                    <i class="icon-angle-left text-general f-20" />
                                </div>
                            </template>
                            <template #arrow-right>
                                <div class="d-middle-center bg-white rounded-circle shadow" style="width:32px;height:32px;">
                                    <i class="icon-angle-right text-general f-20" />
                                </div>
                            </template>
                            <vueper-slide v-for="(i, idx) in imagenes" :key="`slider-${idx}`">
                                <template v-slot:content>
                                    <div class="row mx-0 justify-center">
                                        <el-tooltip content="Inactiva" placement="bottom" effect="light">
                                            <i v-if="i.estado === 0" class="icon-eye-off f-15 bg-general2 text-white p-2 br-br-15 position-absolute" style="left:13px;" />
                                        </el-tooltip>
                                        <div class="br-12 d-middle-center border content-slider" style="height:353px;width:353px;" :class="{'disabled-div':i.estado == 0}">
                                            <img :src="i.imagen" class="w-100 h-100 br-12 obj-cover" />
                                        </div>
                                        <div class="col-12">
                                            <p class="text-general text-center f-20 f-600 mt-2">
                                                <el-tooltip content="Clics" placement="bottom" effect="light">
                                                    <i class="icon-cursor" />
                                                </el-tooltip>
                                                {{ i.clicks }}
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </vueper-slide>
                        </vueper-slides> -->

                        <VueSlickCarousel
                        :style="`height: calc(${containerSliderHeight}px + 75px)`"
                        :arrows="true"
                        :dots="true"
                        :infinite="true"
                        :slides-to-show="1"
                        :slides-to-scroll="1"
                        :variable-width="true"
                        :autoplay="true"
                        :autoplay-speed="5000"
                        >
                            <div v-for="(item, index) in imagenes" :key="index" class="position-relative" :style="`height:${containerSliderHeight}px; width: 500px`">
                                <el-tooltip content="Inactiva" placement="bottom" effect="light">
                                    <div v-if="item.estado === 0" class="estado-img wh-30 rounded-circle d-middle-center bg-general2 position-absolute">
                                        <i class="icon-eye-off f-15 text-white" />
                                    </div>
                                </el-tooltip>
                                <img :src="item.imagen" alt="" :style="`height:${containerSliderHeight}px;max-width: calc(100vw - 120px);`" />
                                <p class="text-general text-center f-20 f-600 mt-28px" style="">
                                    <el-tooltip content="Clicks" placement="bottom" effect="light">
                                        <i class="icon-cursor" />
                                    </el-tooltip>
                                    {{ item.clicks }}
                                </p>
                            </div>
                        </VueSlickCarousel>
                    </template>
                    <template v-else>
                        <div class="d-flex flex-wrap">
                            <div v-for="(i, idx) in imagenes" :key="`fijo-${idx}`" class="mx-0 mt-3" :style="`height: calc(${containerSliderHeight}px + 75px)`">
                                <img :src="i.imagen" class="m-2 br-12" :style="`height:${containerSliderHeight}px;max-width: calc(100vw - 120px);`" />
                                <div class="col-12">
                                    <p class="text-general text-center f-20 f-600 mt-2">
                                        <el-tooltip content="Clics" placement="bottom" effect="light">
                                            <i class="icon-cursor" />
                                        </el-tooltip>
                                        {{ i.clicks }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="row mx-0">
                        <div class="col">
                            <p>Esta sección no contiene elementos</p>
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-auto px-0 ml-3 d-none">
                <button type="button" class="btn-action border mr-2">
                    <el-popover
                    ref="optionsPopover"
                    placement="bottom"
                    trigger="click"
                    width="200"
                    popper-class="p-0 br-none"
                    >
                        <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('editar')">
                            Editar
                        </div>
                        <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('ordenar')">
                            Ordenar sección
                        </div>
                        <div v-if="data.estado == 1" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('activar')">
                            Inactivar
                        </div>
                        <div v-if="data.estado == 0" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('inactivar')">
                            Activar
                        </div>
                        <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('eliminar')">
                            Eliminar
                        </div>
                        <i slot="reference" class="icon-dots-vertical old f-20 text-general cr-pointer" />
                    </el-popover>
                </button>
            </div>
        </div>
    </section>
</template>
<script>
import Landing from '~/services/landing/landing_admin'
import { mapGetters } from 'vuex'
/** Slick-carousel */
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
/** Slick-carousel */
export default {
    components:{
        VueSlickCarousel
    },
    props: {
        saltoLinea: {
            type: Boolean,
            default: false
        },
        data:{
            type:Object,
            default:() => {}
        },
        changes: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            cargando: false,
            verData: false,
            imagenes:[],
            windowWidth: screen.width,
            containerSliderHeight:0
        }
    },
    computed:{
        idSeccion(){
            return this.data.id
        },
        tipoSlider(){
            if(this.data.tipo == 11){
                return true
            }
            return false
        },
        altoMaximo(){
            return this.data.parametro
        },
        totalClicks(){
            return _.sumBy(this.imagenes, 'clicks')
        },
        activeComponent(){
            return parseInt(this.data.estado) == 0 ? false : true;
        },
        ...mapGetters({
            getListenerUpdater: 'landing/landing/getListenerUpdater',
        }),
    },
    watch:{
        idSeccion(value){
            if(value){
                this.loadImagenes()
            }
        },
        changes(){
            this.loadImagenes()
        },
        getListenerUpdater(){
            if(this.getListenerUpdater.type === 'actualizarData' && this.getListenerUpdater.value === 'imagen' && parseInt(this.getListenerUpdater.idSection) === parseInt(this.idSeccion)){
                this.loadImagenes()
            }
        },
    },
    mounted(){
        this.loadImagenes()
        this.getWidthSetHeight()
    },
    methods:{
        getWidthSetHeight(){
            let windowScreen = window.innerWidth;
            this.containerSliderHeight = (windowScreen*this.altoMaximo)/100
        },
        async loadImagenes(){
            try {
                this.cargando = true;
                const {data} = await Landing.detalleSeccion(this.idSeccion)
                if(data.imagenes.length < 1){
                    return this.verData = false;
                }
                this.imagenes = data.imagenes;
                this.verData = true;
            } catch (error){
                this.error_catch(error)
            } finally {
                this.cargando = false;
            }
        },
        emitirAccion(tipo){
            let accion = { tipo:tipo, idSeccion: this.idSeccion, seccion:'imagen' };
            this.$refs.optionsPopover.doToggle();
            this.$emit('acciones', accion);
        },
    }
}
</script>
<style lang="scss" scoped>
.section-imagenes-component:hover{
    background-color: rgb(220, 242, 252);
}

.item-option{
    height: 32px;
    border-radius: 4px;
    color: 13px;
    &:hover{
        background-color: #F6F9FB;
    }
}
.mt-28px{
	margin-top:28px;
}
.estado-img{
	left: 15px;
    top: 15px;
	&.wh-30{
		max-width: 30px;
		min-width: 30px;
		max-height: 30px;
		min-height: 30px;
	}
	.icon-eye-off{
		left: -1px;
    	position: relative;
	}
}
</style>
